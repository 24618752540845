<template>
  <v-row dense class="d-flex fill-height">
    <v-col class="d-flex justify-center flex-fill fill-height">
      <v-card width="50%" class="mt-10">
        <v-card-title>센터정보</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col><v-text-field dense readonly :value="center.centerName" label="센터명" /></v-col>
          </v-row>
          <v-row dense>
            <v-col><v-text-field dense readonly :value="center.centerCode" label="센터코드" /></v-col>
          </v-row>
          <v-row dense>
            <v-col><v-text-field dense readonly :value="center.companyName" label="회사명" /></v-col>
          </v-row>
          <v-row dense>
            <v-col><v-text-field dense readonly value="모름" label="사업자등록번호" /></v-col>
          </v-row>
          <v-row dense>
            <v-col><v-text-field dense readonly :value="center.arsNo" label="연락처" /></v-col>
          </v-row>
          <v-row dense>
            <v-col><v-text-field dense readonly value="모름" label="이메일" /></v-col>
          </v-row>
          <v-row dense>
            <v-col><v-text-field dense readonly value="모름" label="센터주소" /></v-col>
          </v-row>
          <v-row dense>
            <v-col><v-text-field dense readonly value="모름" label="서비스대상" /></v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters} from 'vuex'
import api from '@/services/api'
export default {
  name: 'CenterInfo',
  metaInfo: {
    title: '센터 정보'
  },
  data() {
    return {
      center: {}
    }
  },
  methods: {
    list() {
      api.getCenter(this.loggedInUser.counselor.center.centerCode).then(r => { this.center = r.result })
    }
  },
  mounted() {
    this.list()
  },
  computed: {
    ...mapGetters(['loggedInUser'])
  },
  created() {
    this.$store.commit('setAppBarTitle', '센터 정보')
  }
}
</script>
